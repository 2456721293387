<template>
  <ui-button
    ref="button"
    type="button"
    variant="info"
    class="ui-select-button-button"
    @click="open = !open"
  >
    <template
      v-if="icon"
      #left-icon
    >
      <ui-ctk-icon
        :name="icon"
        :class="{
          'has-dot': hasDot
        }"
        class="ui-select-button-button__icon"
        data-test="icon"
      />
    </template>

    <slot />

    <template #right-icon>
      <ui-material-icon
        :name="open
          ? 'arrow_drop_up'
          : 'arrow_drop_down'"
        class="ui-select-button-button__drop-icon"
        data-test="dropdown-icon"
      />
    </template>
  </ui-button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - UiSelectButtonButton
   * @param {boolean} hasDot
   * @param {string} icon
   */
  export default defineComponent({
    name: 'UiSelectButtonButton',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      hasDot: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: 'adjust'
      }
    },
    setup (props) {
      const { state: open } = useModelGetterSetter(props, 'value')

      return {
        open
      }
    },
    methods: {
      focus () {
        // @ts-ignore
        this.$refs.button.focus()
      }
    }
  })
</script>

<style lang="scss" scoped>
.ui-select-button-button__icon {
  position: relative;
}
.ui-select-button-button__icon.has-dot::after {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
  position: absolute;
  content: ' ';
  width: 5px;
  height: 5px;
  border-radius: 5px;
  right: -4px;
  top: 4px;
}
</style>
